import React from 'react';
import './App.css';
import MovingRectangle from './MovingRectangle';
import SpinningCircle from './SpinningCircle';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Link to="/" className="header-button">//////////0/0 TAX AND LP BURNED///////////</Link>
          <a href="https://dexscreener.com/solana/9ZMBHAq9mLRbF3UrPw6oqxJnjT7xZNga7EtHrvvUteAZ" target="_blank" rel="noopener noreferrer" className="header-button">//////////DEXSCREENER LINK//////////</a>
          <a href="https://t.me/ferretmllm" target="_blank" rel="noopener noreferrer" className="header-button">//////////TELEGRAM//////////</a>
          <a href="https://twitter.com/FerretMLLM" target="_blank" rel="noopener noreferrer" className="header-button">//////////TWITTER//////////</a>
        </header>
        <Routes>
          <Route path="/" element={<MainContent />} />
        </Routes>
      </div>
    </Router>
  );
}

function MainContent() {

  return (
    <div>
      {[...Array(10)].map((_, i) => <MovingRectangle key={i} number={i + 1} />)}
      <div className="sparkly-title">*mlem* Ferret MLLM *mlem* </div>
      <div className="center-box">
        {[...Array(3)].map((_, i) => <SpinningCircle key={i} />)}
        <div className="mint-video-wrapper">
          <a href="https://aiis.dev/?id=clr2oa8t10001k7u2pibiuof8" target="_blank" rel="noopener noreferrer">
            <button className="flashing-button">DEPLOYED ON AIISDEV</button>
          </a>
        </div>
        <p className="rainbow-text">DEX SCREENER LINK</p>
        <div className="video-container">
          <iframe 
            width="560" 
            height="315" 
            src="https://youtube.com/embed/bkuDXqNv-GY?si=Xt4Ijb68W8vshgn3" 
            frameBorder="0" 
            allow="autoplay; encrypted-media" 
            allowFullScreen
            title="Video"
            style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          ></iframe>
        </div>
      </div>
    </div>
  );
}



export default App;
